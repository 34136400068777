select.estilo_field option.color1
{
    background-color: #05294F;
}
select.estilo_field option.color2
{
    background-color: #004890;
}
select.estilo_field option.color3
{
    background-color: #5B78AA;
}
select.estilo_field option.color4
{
    background-color: #06ACCD;
}
select.estilo_field option.color5
{
    background-color: #53B4C6;
}
select.estilo_field option.color6
{
    background-color: #99CA3E;
}
select.estilo_field option.color7
{
    background-color: #909090;
}