.mb-15{
    margin-bottom:15px;
}

.mt-31
{
    margin-top:31px;
}

.fila
{
    display: flex;    
    align-items: center;
}

.fila span
{
    flex:2 1 auto;
}

.fila select
{
    flex:2 1 70px;
}

.paddingSwitch{
    margin: 10px;
}