.preview-button
{
    display: flex;
    justify-content: center;    
    flex-direction: column;
    padding-bottom: 10px;
}

.preview-component
{
    margin: 20px 0px 30px 0px;
    display: flex;    
    gap: 10px;
    align-items: center;
}