.du-box {
    background-color: #fffbfb;
    height: 100% !important;
    bottom: 0;
    top: 0;
    position: fixed;
    width: 100%;
    overflow: hidden;
    z-index: 999999999;
  }
  
  .ducor-loader {
    display: inline-block;
    position: fixed;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    top: calc(50% - 20px);
    margin: auto;
    background-color: #fff;
  
    img {
      position: absolute;
      height: 25px;
      width: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      margin: 0;
      border: 3.4px solid #7467ef;
      border-radius: 50%;
      animation: matx-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #7467ef transparent transparent transparent;
  
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
  
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
  
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
  
  @keyframes matx-loader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }