.mb-15 {
  margin-bottom: 15px;
}

.dx-row {
  border-bottom: 1px solid lightgrey !important;
}

.dx-datagrid-rowsview {
  min-height: 339px !important;
}
