#titulo-error{
  // font-family: "Objectivity-Bold";
  font-size: 40px;
  color:#004890;
}

#subTitulo-error{
    // font-family: "Objectivity-Regular";
    font-size: 30px;
    color:#004890;
}