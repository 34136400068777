/* =============   Inbox   ============= */

.mailbox-sidenav {
  .side-nav-header {
    padding: 10px;
  }
  .nav > li > a {
    color: #666;
    font-weight: 400;
    em.fa, .badge {
      margin-right: 8px;
    }
  }
}

.nav-pills > li.active > a {
  background: $primary;
  color: #fff;
  &:focus, &:hover {
    background: $primary;
    color: #fff;
  }
}

.compose em {
  margin-right: 8px;
}

.mail-right-buttons .btn-default {
  background: #fff;
  &:hover {
    background: #f5f5f5;
  }
}

.table-maillist a {
  color: #666;
}

.inbox-attachments a {
  display: inline-block;
  padding: 0px 5px;
}

.attachment-box {
  position: relative;
  .attachment-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    h5 {
      color: #fff;
      padding: 20px;
    }
    a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.2);
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -20px;
      margin-top: -20px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  &:hover .attachment-overlay {
    opacity: 1;
    visibility: visible;
  }
}

.mail-compose {
  label {
    font-weight: 400;
    font-size: 13px;
    text-transform: capitalize;
  }
  .form-control {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    box-shadow: none;
  }
}

iframe.wysihtml5-sandbox {
  width: 100%;
}

.mail-body {
  padding-top: 15px;
}