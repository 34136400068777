/* =============   Float Chart  ============= */

.flotTip {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  background: #333 !important;
  color: #fff !important;
  border: 0px !important;
}

.flot-chart {
  width: 100%;
  height: 300px;
  .flot-chart-content {
    width: 100%;
    height: 100%;
  }
}