.custom-export-button {
    color: #fff !important;
    background-color: #4b71fa !important;
    border-color: #4b71fa !important;
    
  }
  
  .custom-export-button:hover {
    background-color: #4b71fa;
  }

  .custom-export-button-hidden{
    display: none;
  }