.mb-15{
    margin-bottom:15px;
}

.input-group-custom{
    background-color:#4b71fa !important;
    color:white;
}

.mt-31{
    margin-top:31px;
}

.input-group-validated{
    display: flex;
    flex-direction: column;    
}

.input-group-validated input
{
    width: 100%;
}