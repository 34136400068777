.selected-link {
  color: #4b71fa;
  /* background-color: black; */
}

.badgePadre {
  left: 31px;
  width: 8px !important;
  height: 8px !important;
  background-color: red !important;
  border-color: red !important;
}

.badgeHijo {
  width: 12px !important;
  height: 12px !important;
  left: 15px;
  background-color: red !important;
  border-color: red !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: white;
  margin-top: 6px !important;
}
